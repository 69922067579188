
/* eslint-disable */
import CloudFun, { defineComponent, ref, Condition, Operator } from "@cloudfun/core";
import { VxeFormProps } from "vxe-table";

import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import VideoPlayer from "@/components/video-player/Main.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import VueTagsInput from "@sipec/vue3-tags-input";

export default defineComponent({
  components: {
    VideoPlayer,
    Grid,
    FileUploader,
    VueTagsInput
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const videoRef = ref();
    const uploader = ref<any>({});
    const floorMapModal = ref(false);
    const cameraModal = ref(false);
    const slectedFM = ref<any>();
    const selectedCHs = ref<any>([{ Id: 101, Name: "攝影機" }, { Id: 102, Name: "攝影機2" }]);
    const videoLink = ref("");
    const stopVideo = ref(false);
    var printColumns = [{ field: "Name" }, { field: "Description" }];
    const gridOptions: GridOptions = {
      id: "user",
      title: "地點",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      printConfig: {
        sheetName: "地點清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "地點清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true, filters: [{ checked: false, label: "包含" }], slots: { filter: "filter-name" } },
        { field: "Capacity", title: "容留人數", showHeaderOverflow: true, showOverflow: true },
        {
          field: "Planimetric.Uri", title: "平面圖", showHeaderOverflow: true, showOverflow: true,
          slots: { default: "floor-map" }
        },
        {
          field: "Ivars", title: "攝影機", showHeaderOverflow: true, showOverflow: true,
          slots: { default: "camera" }
        },
        { field: "Published", title: "是否發布", showHeaderOverflow: true, showOverflow: true, resizable: false, width: 100, cellRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否', disabled: true } } }
      ],
      promises: {
        query: model ? (params) => model.dispatch("hotspot/query", params) : undefined,
        queryAll: model ? () => model.dispatch("hotspot/query") : undefined,
        save: model ? (params) => model.dispatch("hotspot/save", params) : undefined,
      },
      modalConfig: { width: 600, showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        { field: "Name", title: "名稱", span: 12, itemRender: { name: "$input", props: { placeholder: "名稱" } } },
        { field: "Capacity", title: "容留人數", span: 12, itemRender: { name: "$input", props: { type: 'number', placeholder: "名稱" } } },
        { field: "Cover.Uri", title: "封面圖", span: 12, slots: { default: "column-cover" } },
        { field: "Planimetric.Uri", title: "平面圖", span: 24, slots: { default: "column-planimetric" } },
        { field: "Published", title: "是否發布", span: 24, itemRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否' } } },
        { field: "Longitude", title: "經度", span: 12, itemRender: { name: "$input" } },
        { field: "Latitude", title: "緯度", span: 12, itemRender: { name: "$input" } },
        // { field: 'Address.Line', title: '地址', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入地址' } } },
        { field: 'LinkUrl', title: '連結網址', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入連結網址' } } },
        { field: 'YoutubeUrl', title: 'Youtube連結', titleWidth: 100, span: 24, slots: { default: "column-youtube-url" } },
        // { field: "Description", title: "說明", span: 24, itemRender: { name: "$input", props: { placeholder: "說明" } } },
      ],
      rules: {
        Name: [{ required: true }],
        Capacity: [{ required: true }],
      }
    };

    const showFloorMap = (data: any) => {
      slectedFM.value = data.Planimetric?.Uri;
      floorMapModal.value = true;
    }

    const showCameraList = (data: any) => {
      let Ivar = data.Ivars[0];
      selectedCHs.value = Ivar.Channels;
      cameraModal.value = true;
    }

    const playVideo = (link: any) => {
      videoLink.value = link;
      stopVideo.value = false;
    };

    return {
      cameraModal,
      grid,
      uploader,
      gridOptions,
      formOptions,
      floorMapModal,
      showFloorMap,
      showCameraList,
      slectedFM,
      selectedCHs,
      VideoPlayer,
      videoRef,
      videoLink,
      playVideo,
      stopVideo,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async uploaderFilter(current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    }
  }
});
