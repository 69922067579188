<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">地點管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })">
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span>
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
        <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="text" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字" @keyup.enter="grid.refresh" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
        <!-- <div class="flex mt-2 sm:mt-0">
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid ref="grid" v-bind="gridOptions" @addNewRow="(row, callback) => { row.Published = false; callback(); }"
          @removeSelectedRows="hideBatchDropDown">
          slots: { default: "column-photo-and-remark" }
          <template #modal="{ row, submit, reset }">
            <vxe-form ref="form" v-bind="formOptions" :data="row" @reset="reset" @submit="submit">
              <template #column-cover="{ data }">
                <file-uploader :ref="el => uploader = el" id="hotspot-cover-uri"
                  style="min-width: 150px; max-width: 150px; min-height: 150px; max-height: 180px;" class="mr-10"
                  mode="image" :modelValue="data.Cover?.Uri" :action="uploadAction" :autoUpload="true"
                  :limitedWidth="2000" :limitedHeight="2000"
                  @update:modelValue="(value) => { if (data.Cover) data.Cover.Uri = value; else data.Cover = { Uri: value }; }"
                  @filter="uploaderFilter" />
              </template>
              <template #column-planimetric="{ data }">
                <file-uploader :ref="el => uploader = el" id="hotspot-planimetric"
                  style="min-width: 150px; max-width: 150px; min-height: 150px; max-height: 220px;" class="mr-10"
                  mode="image" :modelValue="data.Planimetric?.Uri" :action="uploadAction" :autoUpload="true"
                  @update:modelValue="(value) => { if (data.Planimetric) data.Planimetric.Uri = value; else data.Planimetric = { Uri: value }; }"
                  @filter="uploaderFilter" />
              </template>
              <template #column-youtube-url="{ data }">
                <VueTagsInput :tags="data.YoutubeUrl ? data.YoutubeUrl.split(',') : []" placeholder="增加連結"
                  @tags-changed="tags => data.YoutubeUrl = tags.map(e => e.text).join(',')" />
              </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button type="submit" status="primary" content="確認"
              @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })">
            </vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
              <input type="checkbox" class="input input--switch border mt-4 ml-2" :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)" />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{ option.label }}
                </div>
                <input v-model.trim="option.data" :disabled="!option.checked" type="text"
                  class="input pl-12 w-full border col-span-4" placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)" />
              </div>
            </div>
          </template>
          <template #floor-map="{ row }">
            <vxe-button @click="showFloorMap(row)">查看平面圖</vxe-button>

          </template>
          <template #camera="{ row }">
            <vxe-button @click="showCameraList(row)">攝影機</vxe-button>

          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <vxe-modal v-model="floorMapModal" title="平面圖上傳" width="800" height="400" resize>
      <file-uploader :ref="el => uploader = el" id="hotspot-cover-uri"
        style="min-width: 400px; max-width: 600px; min-height: 300px; max-height: 500px;" class="mr-10" mode="image"
        :modelValue="slectedFM" :action="uploadAction" :autoUpload="true" :limitedWidth="2000" :limitedHeight="2000"
        @update:modelValue="(value) => { if (slectedFM) slectedFM = value; else slectedFM = value; }"
        @filter="uploaderFilter" />
    </vxe-modal>
    <vxe-modal v-model="cameraModal" title="攝影機列表" width="800" height="600" resize @close="stopVideo = true">
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 lg:col-span-4">
          <h3></h3>
          <table class="table dark">
            <thead>
              <tr>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">編號</th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">名稱</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="channel in selectedCHs" :key="channel.Id">
                <td class="border-b dark:border-dark-5">
                  {{ channel.Id }}
                </td>
                <td class="border-b dark:border-dark-5">
                  {{ channel.Name }}
                  <span v-if="channel.LinkUrl">
                    (<a class="text-blue-700" @click="playVideo(channel.LinkUrl)" href="javascript:void(0)">影像連結</a>)
                  </span>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
        <div class="col-span-12 lg:col-span-8">
          <VideoPlayer ref="videoRef" :videoUrl="videoLink" :stopVideo="stopVideo" />

        </div>
      </div>

    </vxe-modal>
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { defineComponent, ref, Condition, Operator } from "@cloudfun/core";
import { VxeFormProps } from "vxe-table";

import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import VideoPlayer from "@/components/video-player/Main.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import VueTagsInput from "@sipec/vue3-tags-input";

export default defineComponent({
  components: {
    VideoPlayer,
    Grid,
    FileUploader,
    VueTagsInput
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const videoRef = ref();
    const uploader = ref<any>({});
    const floorMapModal = ref(false);
    const cameraModal = ref(false);
    const slectedFM = ref<any>();
    const selectedCHs = ref<any>([{ Id: 101, Name: "攝影機" }, { Id: 102, Name: "攝影機2" }]);
    const videoLink = ref("");
    const stopVideo = ref(false);
    var printColumns = [{ field: "Name" }, { field: "Description" }];
    const gridOptions: GridOptions = {
      id: "user",
      title: "地點",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      printConfig: {
        sheetName: "地點清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "地點清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true, filters: [{ checked: false, label: "包含" }], slots: { filter: "filter-name" } },
        { field: "Capacity", title: "容留人數", showHeaderOverflow: true, showOverflow: true },
        {
          field: "Planimetric.Uri", title: "平面圖", showHeaderOverflow: true, showOverflow: true,
          slots: { default: "floor-map" }
        },
        {
          field: "Ivars", title: "攝影機", showHeaderOverflow: true, showOverflow: true,
          slots: { default: "camera" }
        },
        { field: "Published", title: "是否發布", showHeaderOverflow: true, showOverflow: true, resizable: false, width: 100, cellRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否', disabled: true } } }
      ],
      promises: {
        query: model ? (params) => model.dispatch("hotspot/query", params) : undefined,
        queryAll: model ? () => model.dispatch("hotspot/query") : undefined,
        save: model ? (params) => model.dispatch("hotspot/save", params) : undefined,
      },
      modalConfig: { width: 600, showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        { field: "Name", title: "名稱", span: 12, itemRender: { name: "$input", props: { placeholder: "名稱" } } },
        { field: "Capacity", title: "容留人數", span: 12, itemRender: { name: "$input", props: { type: 'number', placeholder: "名稱" } } },
        { field: "Cover.Uri", title: "封面圖", span: 12, slots: { default: "column-cover" } },
        { field: "Planimetric.Uri", title: "平面圖", span: 24, slots: { default: "column-planimetric" } },
        { field: "Published", title: "是否發布", span: 24, itemRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否' } } },
        { field: "Longitude", title: "經度", span: 12, itemRender: { name: "$input" } },
        { field: "Latitude", title: "緯度", span: 12, itemRender: { name: "$input" } },
        // { field: 'Address.Line', title: '地址', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入地址' } } },
        { field: 'LinkUrl', title: '連結網址', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入連結網址' } } },
        { field: 'YoutubeUrl', title: 'Youtube連結', titleWidth: 100, span: 24, slots: { default: "column-youtube-url" } },
        // { field: "Description", title: "說明", span: 24, itemRender: { name: "$input", props: { placeholder: "說明" } } },
      ],
      rules: {
        Name: [{ required: true }],
        Capacity: [{ required: true }],
      }
    };

    const showFloorMap = (data: any) => {
      slectedFM.value = data.Planimetric?.Uri;
      floorMapModal.value = true;
    }

    const showCameraList = (data: any) => {
      let Ivar = data.Ivars[0];
      selectedCHs.value = Ivar.Channels;
      cameraModal.value = true;
    }

    const playVideo = (link: any) => {
      videoLink.value = link;
      stopVideo.value = false;
    };

    return {
      cameraModal,
      grid,
      uploader,
      gridOptions,
      formOptions,
      floorMapModal,
      showFloorMap,
      showCameraList,
      slectedFM,
      selectedCHs,
      VideoPlayer,
      videoRef,
      videoLink,
      playVideo,
      stopVideo,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async uploaderFilter(current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    }
  }
});
</script>
