<template>
  <div class="container">
    <video ref="player" class="video-js" muted controls width="500" height="400">

    </video>
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import { defineComponent, ref, onMounted, watch } from 'vue';
import Video, { VideoJsPlayer } from "video.js";

export default defineComponent({
  props: {
    videoUrl: {
      type: String,
      default: "",
      required: true
    },
    stopVideo: {
      type: Boolean,
      default: false
    }

  },
  setup(props) {
    const player = ref();
    let video: VideoJsPlayer | null = null;
    onMounted(() => {
      video = Video(player.value);
    });

    watch(() => props.videoUrl, (value) => {
      if (!value || !video) return;

      video.src(value);
      video.load();
    });

    watch(() => props.stopVideo, (value) => {
      if (!value || !video) return;

      video.pause();
      video.loadMedia({
        src: ""
      }, () => {
        //
      })
    });

    return {
      player
    }
  },
  // mounted() {
  //   const that = this;
  //   this.hls = new Hls();
  //   const video: any = this.$refs.video_player;
  //   this.hls.attachMedia(video);
  //   // this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
  //   //   // console.log('ready')
  //   //   that.hls.loadSource(this.videoUrl)
  //   // });

  //   // this.hls.on(Hls.Events.MANIFEST_PARSED, function () {
  //   //   video.play();
  //   // });

  //   this.hls.on(Hls.Events.ERROR, function (event: any, data: { fatal: any; type: any; }) {
  //     if (data.fatal) {
  //       switch (data.type) {
  //         case Hls.ErrorTypes.NETWORK_ERROR:
  //           // try to recover network error
  //           console.log('fatal network error encountered, try to recover');
  //           that.hls.startLoad();
  //           break;
  //         case Hls.ErrorTypes.MEDIA_ERROR:
  //           console.log('fatal media error encountered, try to recover');
  //           that.hls.recoverMediaError();
  //           break;
  //         default:
  //           // cannot recover
  //           that.hls.destroy();
  //           break;
  //       }
  //     }
  //   });
  // },
})
</script>
<style scoped>
</style>
